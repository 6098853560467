import React from "react";

import Layout from "../../components/Layout";
import Background from "../../components/Background";
import Hero from "../../components/Hero";
import MotifColumn from "../../components/MotifColumn";

const UploadSuccesPage = () => {
  return (
    <Layout>
      <Background>
        <Hero>
          <MotifColumn>
            <h1> Thank you </h1>
            <p>Someone from our team will get back to you momentarily</p>
          </MotifColumn>
        </Hero>
      </Background>
    </Layout>
  );
};

export default UploadSuccesPage;
